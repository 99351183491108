var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--moon-pay" },
    [
      _c(
        "mew-popup",
        {
          attrs: {
            show: _vm.open,
            "has-buttons": false,
            "has-title": false,
            "has-padding": false,
            "max-width": "450",
            "left-btn": _vm.leftBtn,
            scrollable: "",
            "has-body-content": "",
          },
        },
        [
          _vm.step === 0
            ? _c(
                "div",
                [
                  _vm.open
                    ? _c("mew-tabs", {
                        staticClass: "pt-3",
                        class: _vm.addTokenPadding ? "top-container" : "",
                        attrs: {
                          items: _vm.tabItems,
                          "active-tab": _vm.activeTab,
                          "active-color": "greenPrimary",
                          "has-underline": "",
                        },
                        on: { onTab: _vm.onTab },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "tabContent1",
                              fn: function () {
                                return [
                                  _c("buy-eth-component", {
                                    attrs: {
                                      "order-handler": _vm.orderHandler,
                                      close: _vm.close,
                                      tab: _vm.activeTab,
                                      "default-currency": _vm.defaultCurrency,
                                      "in-wallet": _vm.inWallet,
                                      "supported-buy": _vm.supportedNetwork,
                                    },
                                    on: {
                                      selectedCurrency: _vm.setSelectedCurrency,
                                      openProviders: _vm.openProviders,
                                      selectedFiat: _vm.setSelectedFiat,
                                      hideMoonpay: _vm.hideMoonpay,
                                      simplexQuote: _vm.setSimplexQuote,
                                      toAddress: _vm.setToAddress,
                                      success: _vm.buySuccess,
                                      openTokenSelect: _vm.checkTokenPadding,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            _vm.sellSupported
                              ? {
                                  key: "tabContent2",
                                  fn: function () {
                                    return [
                                      _c("sell-eth-component", {
                                        attrs: {
                                          "order-handler": _vm.orderHandler,
                                          close: _vm.close,
                                          tab: _vm.activeTab,
                                          "in-wallet": _vm.inWallet,
                                          "default-currency":
                                            _vm.defaultCurrency,
                                        },
                                        on: {
                                          selectedCurrency:
                                            _vm.setSelectedCurrency,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.step === 1
            ? _c("BuyProviderComponent", {
                attrs: {
                  "order-handler": _vm.orderHandler,
                  close: _vm.close,
                  "in-wallet": _vm.inWallet,
                  "only-simplex": _vm.onlySimplex,
                  "selected-currency": _vm.selectedCurrency,
                  "selected-fiat": _vm.selectedFiat,
                  "buy-obj": _vm.buyObj,
                  "simplex-quote": _vm.simplexQuote,
                  "to-address": _vm.toAddress,
                },
                on: {
                  close: function ($event) {
                    _vm.step = 0
                  },
                  openProviders: _vm.openProviders,
                  reset: _vm.reset,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }